//main variables and mixins

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #dee2e6 !default;
$gray-300: #bdbdbd !default;
$gray-400: #999999 !default;
$gray-500: #afb4b9 !default;
$gray-600: #818991 !default;
$gray-700: #495057 !default;
$gray-800: #495057 !default;
$gray-900: #495057 !default;
$black:    #000 !default;

$blue:    #548fe7 !default;
$indigo:  #6610f2 !default;
$purple:  #8d60e2 !default;
$pink:    #d63384 !default;
$red:     #b9313f !default;
$orange:  #fd7e14 !default;
$yellow:  #fac935 !default;
$green:   #198754 !default;
$teal:    #3accc5 !default;
$cyan:    #50d4ee !default;

$primary:       $yellow !default;
$secondary:     $gray-600 !default;
$success:       $teal !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$body-color: $gray-500 !default;

$component-active-color: $gray-900 !default;
$component-active-bg: $gray-100 !default;

//main options

$spacer: 1.25rem;
$enable-rounded: true;
$enable-caret: false;
$enable-shadows: true;
$enable-gradients: true;

$font-family: Oswald;
$font-family-sans-serif: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;


//close button

$btn-close-width:            1.25em;
$btn-close-height:           $btn-close-width;
$btn-close-padding-x:        .5em;
$btn-close-padding-y:        $btn-close-padding-x;
$btn-close-color:            $black;
// $btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
// $btn-close-focus-shadow:     $input-btn-focus-box-shadow;
$btn-close-opacity:          .4;
$btn-close-hover-opacity:    .8;
$btn-close-focus-opacity:    1;
$btn-close-disabled-opacity: .25;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%);


// .App-logo {
// 	height: 40vmin;
// 	pointer-events: none;
//   }
  
  @media (prefers-reduced-motion: no-preference) {
	/* .App-logo {
	  animation: App-logo-spin infinite 20s linear;
	} */
  }



/* user styles  */
.page-container{
	max-width: 420px;
	text-align: center;
	margin: auto;
}
.page-prof.style-1 {
	max-width: 8em;
	margin: 4rem;
}

@media (max-width: 768px) {
	.page-prof.style-1 {
		max-width: 6em;
		margin: 2rem;
	}
}


/* code for the sun  */
.sun-logo{
	position: absolute;
	bottom: 2em;
	left: 35px;
	margin: -15px 0 0 -15px;
	width: 40px;
	height: 40px;
}
.sun-logo *, .sun-logo *:before, .sun-logo *:after{
	position: absolute;
}

.sun-logo {
	.rings{
		left: 15px;
		bottom: 5px;
		z-index: 1;
	}
	
	.rings div:before, .rings div:after{
		content: "";
		z-index: 2;
		top: 0;
		left: 0;
		height: inherit;
		width: inherit;
		background: inherit;
	}
	.rings div:before{
		transform: rotate(60deg);
	}
	.rings div:after{
		transform: rotate(-60deg);
	}
	.rings.rotate div:nth-child(even){
		animation: rotateR 12s infinite linear;
	}
	.rings.rotate div:nth-child(odd){
		animation: rotateL 12s infinite linear;
		transform: rotate(15deg);
	}
	.rings div:nth-child(1){
		animation-duration: 45s;
		background: rgba(255, 230, 84, 0.5);
		width: 20px;
		height: 20px;
		margin: -10px 0 0 -10px;
	}
	.rings div:nth-child(2){
		animation-duration: 49s;
		background: rgba(255, 185, 6, 0.5);
		width: 19px;
		height: 19px;
		margin: -9.5px 0 0 -9.5px;
	}
	.rings div:nth-child(3){
		animation-duration: 56s;
		background: rgba(255, 130, 5, 0.5);
		width: 18px;
		height: 18px;
		margin: -9px 0 0 -9px;
	}
	.rings idiv:nth-child(4){
		animation-duration: 93s;
		background: rgba(255, 90, 0, 0.5);
		width: 17px;
		height: 17px;
		margin: -8.5px 0 0 -8.5px;
	}	
}

/* code for the large sun  */
.sun{
	position: absolute;
	top: 14em;
	left: 50%;
	margin: -150px 0 0 -150px;
	width: 300px;
	height: 300px;
}
.sun *, .sun *:before, .sun *:after{
	position: absolute;
}

.sun {
	.rings{
		top: 50%;
		left: 50%;
		z-index: 1;
	}
	.rings div:before, .rings div:after{
		content: "";
		z-index: 2;
		top: 0;
		left: 0;
		height: inherit;
		width: inherit;
		background: inherit;
	}
	.rings div:before{
		transform: rotate(60deg);
	}
	.rings div:after{
		transform: rotate(-60deg);
	}
	.rings.rotate div:nth-child(even){
		animation: rotateR 12s infinite linear;
	}
	.rings.rotate div:nth-child(odd){
		animation: rotateL 12s infinite linear;
		transform: rotate(15deg);
	}
	.rings div:nth-child(1){
		animation-duration: 45s;
		background: rgba(255, 230, 84, 0.5);
		width: 200px;
		height: 200px;
		margin: -100px 0 0 -100px;
	}
	.rings div:nth-child(2){
		animation-duration: 49s;
		background: rgba(255, 185, 6, 0.5);
		width: 190px;
		height: 190px;
		margin: -95px 0 0 -95px;
	}
	.rings div:nth-child(3){
		animation-duration: 56s;
		background: rgba(255, 130, 5, 0.5);
		width: 180px;
		height: 180px;
		margin: -90px 0 0 -90px;
	}
	.rings idiv:nth-child(4){
		animation-duration: 93s;
		background: rgba(255, 90, 0, 0.5);
		width: 170px;
		height: 170px;
		margin: -85px 0 0 -85px;
	}
}


/* animations */
@-webkit-keyframes rotateR{
	from{
		-webkit-transform: rotate(0deg);
	}
	to{
		-webkit-transform: rotate(360deg);
	}
}
@-moz-keyframes rotateR{
	from{
		-moz-transform: rotate(0deg);
	}
	to{
		-moz-transform: rotate(360deg);
	}
}
@-ms-keyframes rotateR{
	from{
		-ms-transform: rotate(0deg);
	}
	to{
		-ms-transform: rotate(360deg);
	}
}
@-o-keyframes rotateR{
	from{
		-o-transform: rotate(0deg);
	}
	to{
		-o-transform: rotate(360deg);
	}
}
@keyframes rotateR{
	from{
		transform: rotate(0deg);
	}
	to{
		transform: rotate(360deg);
	}
}
@-webkit-keyframes rotateL{
	from{
		-webkit-transform: rotate(360deg);
	}
	to{
		-webkit-transform: rotate(0deg);
	}
}
@-moz-keyframes rotateL{
	from{
		-moz-transform: rotate(360deg);
	}
	to{
		-webkit-transform: rotate(0deg);
	}
}
@-ms-keyframes rotateL{
	from{
		-ms-transform: rotate(360deg);
	}
	to{
		-ms-transform: rotate(0deg);
	}
}
@-o-keyframes rotateL{
	from{
		-o-transform: rotate(360deg);
	}
	to{
		-o-transform: rotate(0deg)
	}
}
@keyframes rotateL{
	from{
		transform: rotate(360deg);
	}
	to{
		transform: rotate(0deg);
	}
}




// @mixin inline-animation($duration) {
//     $name: inline-#{unique-id()};
  
//     @keyframes #{$name} {
//       @content;
//     }
  
//     animation-name: $name;
//     animation-duration: $duration;
//     animation-iteration-count: infinite;
//   }
  
//   .pulse {
//     @include inline-animation(2s) {
//       from { background-color: yellow }
//       to { background-color: red }
//     }
//   }
  
//   @mixin uplift-arrow($name, $top-or-bottom, $left-or-right) {
//     .arrow-#{$name} {
//       background-image: url("/icons/chevron-#{$name}-16.svg");
//       position: absolute;
//       #{$top-or-bottom}: 0;
//       #{$left-or-right}: 0;
//     }
//   }
  
//   @include uplift-arrow("up", top, center); 
//   @include uplift-arrow("down", top, center); 
//   @include uplift-arrow("right", top, center); 
//   @include uplift-arrow("left", top, center); 


@import '~bootstrap/scss/bootstrap.scss';
